var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.extraActions && _vm.extraActions.length > 0)?_c('div',[(!_vm.isHovered && !_vm.menuIsActive && _vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-btn',{attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$dots-vertical")])],1)],1):_vm._e(),(_vm.isHovered || _vm.menuIsActive || !_vm.$vuetify.breakpoint.mdAndUp)?_c('v-item-group',{staticClass:"row-list-buttons__wrapper v-btn-toggle"},[_vm._l((_vm.primaryActions),function(button){return [(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-tooltip',{key:button.action,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                'primary-button':
                  !_vm.isHovered && !_vm.menuIsActive && !_vm.$vuetify.breakpoint.mdAndUp,
              },attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.fire(button.action)}}},on),[_c('v-icon',{attrs:{"color":button.color}},[_vm._v(_vm._s(button.icon))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(button.title)+" ")])]:[_c('v-btn',{key:button.action,class:{
            'primary-button':
              !_vm.isHovered && !_vm.menuIsActive && !_vm.$vuetify.breakpoint.mdAndUp,
          },attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.fire(button.action)}}},[_c('v-icon',{attrs:{"color":button.color}},[_vm._v(_vm._s(button.icon))])],1)]]}),_c('v-menu',{attrs:{"offset-y":"","offset-x":"","offset-overflow":"","close-on-click":true},on:{"input":function($event){return _vm.$emit('menuChanged', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$dots-vertical")])],1)]}}],null,false,1773188802),model:{value:(_vm.menuIsActive),callback:function ($$v) {_vm.menuIsActive=$$v},expression:"menuIsActive"}},[_c('v-list',{staticClass:"event-list-menu"},_vm._l((_vm.extraActions),function(element,i){return _c('div',{key:i},[_c('v-list-item',{on:{"click":function($event){return _vm.fire(element.action)}}},[_c('v-list-item-title',{staticClass:"d-flex align-center",class:element.class ? element.class : ''},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":element.color}},[_vm._v(" "+_vm._s(element.icon)+" ")]),_vm._v(" "+_vm._s(element.title)+" ")],1)],1)],1)}),0)],1)],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }