import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  data() {
    return {
      statsMode: null,
    };
  },
  computed: {
    ...mapGetters({
      stats: "dashboard/getStats",
    }),
    webDraftsExist() {
      return this.stats && this.stats.web_drafts > 0;
    },
    mobileDraftsExist() {
      return this.stats && this.stats.mobile_drafts > 0;
    },
    employeesExist() {
      return this.stats && this.stats.employees > 0;
    },
    usersExist() {
      return this.stats && this.stats.users > 0;
    },
    servicesExist() {
      return this.stats && this.stats.services > 0;
    },
    categoriesExist() {
      return this.stats && this.stats.categories > 0;
    },
    bookingsExist() {
      return this.stats && this.stats.bookings > 0;
    },
    unavailableBookingSlotsExist() {
      return this.stats && this.stats.unavailable_booking_slots > 0;
    },
    newsletterExist() {
      return this.stats && this.stats.newsletter > 0;
    },
    simpleStoreProductExist() {
      return this.stats && this.stats.simple_store_products > 0;
    },
    subscriptionsExist() {
      return this.stats && this.stats.subscriptions > 0;
    },
    userSubscriptionsExist() {
      return this.stats && this.stats.user_subscriptions > 0;
    },
    simpleStoreProductTransactionExist() {
      return this.stats && this.stats.simple_store_product_transactions > 0;
    },
  },
  created() {
    if (this.statsMode) {
      const statsExistence = {
        [this.$helpers.statsMode.employees]: this.employeesExist,
        [this.$helpers.statsMode.webDrafts]: this.webDraftsExist,
        [this.$helpers.statsMode.mobileDrafts]: this.mobileDraftsExist,
        [this.$helpers.statsMode.users]: this.usersExist,
        [this.$helpers.statsMode.services]: this.servicesExist,
        [this.$helpers.statsMode.categories]: this.categoriesExist,
        [this.$helpers.statsMode.bookings]: this.bookingsExist,
        [this.$helpers.statsMode.simpleStoreProductList]:
          this.simpleStoreProductExist,
        [this.$helpers.statsMode.subscriptions]: this.subscriptionsExist,
        [this.$helpers.statsMode.userSubscriptions]:
          this.userSubscriptionsExist,
        [this.$helpers.statsMode.simpleStoreProductTransactionList]:
          this.simpleStoreProductTransactionExist,
        [this.$helpers.statsMode.newsletter]: this.newsletterExist,
      };

      if (!this.stats || !statsExistence[this.statsMode]) {
        // Don't move it inside fetchStatsDebounce(), it's debounce method and has delay.
        this.setIsSending(true);
        this.fetchStatsDebounce();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchActiveEmployees: "employee/fetchActive",
      fetchStats: "dashboard/fetchStats",
    }),
    fetchStatsDebounce: debounce(function () {
      this.fetchStats().then(() => {
        this.setIsSending(false);
      });
    }, 200),
  },
};
