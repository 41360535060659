import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export const bookingStatuses = {
  waiting: "waiting",
  payment: "payment",
  approved: "approved",
  cancelled: "cancelled",
  done: "done",

  colors() {
    return [
      {
        type: bookingStatuses.waiting,
        color: "green--text",
        background: "green-light",
      },
      {
        type: bookingStatuses.payment,
        color: "black--text",
        background: "gray",
      },
      {
        type: bookingStatuses.approved,
        color: "green--text",
        background: "green-light",
      },
      {
        type: bookingStatuses.cancelled,
        color: "accent_light--text",
        background: "red-light",
      },
      {
        type: bookingStatuses.done,
        color: "blue--text",
        background: "snow_gray",
      },
      {
        type: "error",
        color: "black--text",
        background: "gray",
      },
    ];
  },

  inputValues() {
    return [
      // Commented values need to be fully supported on backend
      // We will need it soon
      // {
      //   text: trans('waiting'),
      //   value: bookingStatuses.waiting,
      // },
      {
        text: trans("approved"),
        value: bookingStatuses.approved,
      },
      {
        text: trans("payment"),
        value: bookingStatuses.payment,
      },
      {
        text: trans("cancelled"),
        value: bookingStatuses.cancelled,
      },
      {
        text: trans("done"),
        value: bookingStatuses.done,
      },
    ];
  },
};
