import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export default {
  "items-per-page-text": trans("per_page"),
  "items-per-page-options": [5, 10, 15, 25, 50, 100, 250],
  "show-first-last-page": true,
  "first-icon": "$chevron-double-left",
  "last-icon": "$chevron-double-right",
  "prev-icon": "$chevron-left",
  "next-icon": "$chevron-right",
};
