import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import api from "@/lib/calendesk-js-library/api/api";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import planActions from "@/calendesk/mixins/planActions";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [planActions],
  data() {
    return {
      isLoadingNewWebsiteButton: false,
    };
  },
  computed: {
    ...mapGetters({
      shouldRefreshWebDrafts: "webDraft/shouldRefreshWebDrafts",
      getTenant: "setup/getTenant",
    }),
  },
  methods: {
    ...mapActions({
      refreshWebDrafts: "webDraft/refreshWebDrafts",
      fetchWebDrafts: "webDraft/fetchAll",
    }),
    handleDisableDraftsClick(drafts) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("drafts_disable_confirm_dialog_title"),
        warning: this.$trans("drafts_disable_confirm_dialog_warning"),
        importantInformation: this.$trans(
          "drafts_disable_confirm_dialog_inportant_information"
        ),
        confirmationText: this.$trans("disable"),
        confirmAction: () => {
          this.disableDrafts(drafts);
        },
      });
    },
    disableDrafts(drafts) {
      pushEvent("disableWebDraft");

      this.setCommonDialogLoader(true);

      const promises = drafts.map((draft) => api.disableWb2Draft(draft.id));

      Promise.all(promises)
        .then(() => {
          successNotification("wb_website_disabled_success");
        })
        .catch((error) => errorNotification(null, error))
        .finally(() => {
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
          this.refreshWebDrafts();
        });
    },
    handleChangeDraftNameClick(draft) {
      this.openDialog({
        type: dialogTypes.CHANGE_DRAFT_NAME,
        size: dialogSize.SMALL,
        title: this.$trans("update_draft_name_title"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
        data: {
          id: draft.id,
          description: draft.description,
          type: this.$helpers.draftType.WEB,
        },
      });
    },
    handlePublishDraftClick(draft) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        importantInformation: "",
        confirmationText: this.$trans("ok"),
        confirmAction: () => {
          this.publishDraft(draft);
        },
      });
    },
    publishDraft(draft) {
      pushEvent("publishWebDraft");

      this.setCommonDialogLoader(true);

      api
        .publishWb2Draft(draft.id)
        .then(() => {
          successNotification("wb_publish_success_text");
          this.refreshWebDrafts();
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
        });
    },
    handleAddNewWebsiteClick() {
      pushEvent("createNewWebsite");

      if (this.canAddWebsite) {
        this.isLoadingNewWebsiteButton = true;
        this.openWebsiteBuilder();
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleEditNewWebsiteClick(draft) {
      pushEvent("openWebBuilder");
      this.openWebsiteBuilder(draft);
    },
    handleDeleteWebsitesClick(drafts) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("drafts_remove_confirm_dialog_title"),
        warning: this.$trans("drafts_remove_confirm_dialog_warning"),
        confirmationText: this.$trans("remove"),
        confirmAction: () => {
          this.removeDrafts(drafts);
        },
      });
    },
    removeDrafts(drafts) {
      pushEvent("removeWebDraft");

      this.setCommonDialogLoader(true);

      const promises = drafts.map((draft) => api.removeWb2Draft(draft.id));

      Promise.all(promises)
        .then(() => {
          this.refreshWebDrafts();
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
        });
    },
    handleSetMainWebsiteClick(draft) {
      pushEvent("setMainWebDraft");

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("ok"),
        confirmAction: () => {
          this.setMainDomain(draft);
        },
      });
    },
    setMainDomain(draft) {
      pushEvent("changeMainDomainWebDraft");

      this.setCommonDialogLoader(true);
      api
        .setDraftDomainAsMain(draft.id)
        .then(() => {
          successNotification("wb_set_as_main_website_success");
        })
        .catch((error) => errorNotification(null, error))
        .finally(() => {
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
          this.refreshWebDrafts();
        });
    },
    openWebsiteBuilder(draft = null) {
      api
        .createSessionId()
        .then(({ data }) => {
          const locale = localStorage.getItem("locale").toLowerCase();
          let url =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "") +
            "/";
          let referrer = encodeURIComponent(url);

          if (draft) {
            window.location.href = `${process.env.VUE_APP_WB2_URL}/${locale}/builder/${draft.id}?tenant=${this.getTenant}&session_id=${data.session_id}&referrer=${referrer}`;
          } else {
            window.location.href = `${process.env.VUE_APP_WB2_URL}/${locale}/creator?tenant=${this.getTenant}&session_id=${data.session_id}&referrer=${referrer}`;
          }
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isLoadingNewWebsiteButton = false;
        });
    },
  },
};
