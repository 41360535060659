import {
  setCacheTableData,
  setCacheTableExpandedData,
  getCachedTableData,
  getCachedTableExpandedData,
} from "@/workers/shared-list-worker-api";

export default {
  methods: {
    getTenantCacheKey(cacheKey) {
      if (this.$store.getters["setup/getTenant"]) {
        return `${this.$store.getters["setup/getTenant"]}_${cacheKey}`;
      }

      return cacheKey;
    },
    async getCachedTableData(cacheId) {
      return await getCachedTableData(this.getTenantCacheKey(cacheId));
    },
    async setCacheTableData(data, cacheId) {
      return await setCacheTableData(data, this.getTenantCacheKey(cacheId));
    },
    async getCachedTableExpandedData(cacheId) {
      return await getCachedTableExpandedData(this.getTenantCacheKey(cacheId));
    },
    async setCacheTableExpandedData(data, cacheId) {
      return await setCacheTableExpandedData(
        data,
        this.getTenantCacheKey(cacheId)
      );
    },
  },
};
