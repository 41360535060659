<template>
  <v-container>
    <v-row class="no-data-info">
      <v-col
        cols="12"
        offset-md="3"
        md="6"
        offset-lg="4"
        lg="4"
        class="no-data__col text-center"
      >
        <v-img v-if="image" :src="image" contain />
        <template>
          <span v-if="description" class="text-body-1 black--text">{{
            description
          }}</span>
          <v-btn
            v-if="redirectToRoute && !hideButton"
            color="blue"
            depressed
            dark
            :to="redirectToRoute"
          >
            {{ buttonText }}
          </v-btn>
          <v-btn
            v-else-if="!hideButton"
            color="blue"
            depressed
            dark
            @click="$emit('click')"
          >
            {{ buttonText }}
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NoDataInfo",
  props: {
    description: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: require("@/assets/empty.png"),
    },
    buttonText: {
      type: String,
      default: "",
    },
    redirectToRoute: {
      type: Object,
      default: () => {},
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.no-data-info .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-info {
  padding: 12px;
}

.no-data__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > span,
  & > .v-btn {
    margin: 1rem 0;
  }
}
</style>
