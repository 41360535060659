<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import websiteActions from "@/calendesk/mixins/websiteActions";

export default {
  components: { ListButtons },
  mixins: [websiteActions],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
        {
          action: "edit-name",
          color: "light",
          icon: "$pencil",
          title: this.$trans("update_draft_name_title"),
        },
      ];

      if (this.item.domain && !this.item.domain.is_main) {
        actions.push({
          action: "set-main",
          color: "light",
          icon: "$home",
          title: this.$trans("wb_set_as_main_website"),
        });
      }

      if (this.item.published) {
        actions.push({
          action: "disable",
          color: "light",
          icon: "$close",
          title: this.$trans("disable"),
        });
      } else {
        actions.push({
          action: "publish",
          color: "light",
          icon: "$publish",
          title: this.$trans("publish"),
        });

        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryActions() {
      return [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];
    },
  },
  methods: {
    fire(action) {
      const cloned = this.$helpers.cloneObject(this.item);
      switch (action) {
        case "edit":
          this.handleEditNewWebsiteClick(cloned);
          break;
        case "edit-name":
          this.handleChangeDraftNameClick(cloned);
          break;
        case "publish":
          this.handlePublishDraftClick(cloned);
          break;
        case "set-main":
          this.handleSetMainWebsiteClick(cloned);
          break;
        case "disable":
          this.handleDisableDraftsClick([cloned]);
          break;
        case "delete":
          this.handleDeleteWebsitesClick([cloned]);
          break;
      }
    },
  },
};
</script>
