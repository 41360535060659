<template>
  <div v-if="extraActions && extraActions.length > 0">
    <div v-if="!isHovered && !menuIsActive && $vuetify.breakpoint.mdAndUp">
      <v-btn fab icon small @click.stop>
        <v-icon color="light">$dots-vertical</v-icon>
      </v-btn>
    </div>
    <v-item-group
      v-if="isHovered || menuIsActive || !$vuetify.breakpoint.mdAndUp"
      class="row-list-buttons__wrapper v-btn-toggle"
    >
      <template v-for="button in primaryActions">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-tooltip :key="button.action" bottom>
            <template #activator="{ on }">
              <v-btn
                :class="{
                  'primary-button':
                    !isHovered && !menuIsActive && !$vuetify.breakpoint.mdAndUp,
                }"
                fab
                icon
                small
                v-on="on"
                @click.stop="fire(button.action)"
              >
                <v-icon :color="button.color">{{ button.icon }}</v-icon>
              </v-btn>
            </template>
            {{ button.title }}
          </v-tooltip>
        </template>
        <template v-else>
          <v-btn
            :key="button.action"
            :class="{
              'primary-button':
                !isHovered && !menuIsActive && !$vuetify.breakpoint.mdAndUp,
            }"
            fab
            icon
            small
            @click.stop="fire(button.action)"
          >
            <v-icon :color="button.color">{{ button.icon }}</v-icon>
          </v-btn>
        </template>
      </template>
      <v-menu
        v-model="menuIsActive"
        offset-y
        offset-x
        offset-overflow
        :close-on-click="true"
        @input="$emit('menuChanged', $event)"
      >
        <template #activator="{ on }">
          <v-btn fab icon small @click.stop v-on="on">
            <v-icon color="light">$dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="event-list-menu">
          <div v-for="(element, i) in extraActions" :key="i">
            <v-list-item @click="fire(element.action)">
              <v-list-item-title
                :class="element.class ? element.class : ''"
                class="d-flex align-center"
              >
                <v-icon class="mr-2" :color="element.color">
                  {{ element.icon }}
                </v-icon>
                {{ element.title }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </v-item-group>
  </div>
</template>
<script>
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";

export default {
  name: "ListButtons",
  mixins: [eventActions],
  props: {
    isHovered: {
      type: Boolean,
      default: false,
    },
    hidePreviewOption: {
      type: Boolean,
      default: false,
    },
    extraActions: {
      type: Array,
      default: () => [],
    },
    primaryActions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuIsActive: false,
    };
  },
  methods: {
    fire(action) {
      this.$emit("fireAction", action);
    },
  },
};
</script>
