<template>
  <div v-if="!isSending" class="list mb-16">
    <no-data-info
      v-if="!webDraftsExist"
      :description="$trans('no_websites_info')"
      :button-text="$trans('no_website_info_button')"
      @click="handleAddNewWebsiteClick"
    />
    <template v-else>
      <website-filter
        @filtersChanged="filtersChanged"
        @filtersRestored="filtersRestored"
      />
      <v-data-table
        v-model="selected"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :loading="isLoadingTable"
        :loading-text="$trans('loading')"
        :no-data-text="$trans('nothing_found_here')"
        :no-results-text="$trans('nothing_found_here')"
        :options.sync="tableOptions"
        :server-items-length="total"
        :show-select="!noData"
        class="calendesk-datatable"
        mobile-breakpoint="960"
      >
        <template #[`header.data-table-select`]>
          <div class="text-center">
            <v-simple-checkbox
              v-model="selectAllState"
              :ripple="false"
              :indeterminate="isIndeterminateForSelectAll"
              color="primary"
              @input="selectAllItemsOnChange"
            />
          </div>
        </template>

        <template #[`item`]="{ item }">
          <website-row
            :item="item"
            :is-selected="
              isRowSelected({
                id: item.id,
              })
            "
            @selectOnChange="itemRowEventOnSelectChange"
          />
        </template>
      </v-data-table>
      <table-footer-menu
        :on-select-actions="onSelectActions"
        :selected="selected"
        :selection-map="(item) => item"
      />
    </template>
  </div>
</template>

<script>
import statsActions from "@/calendesk/mixins/statsActions";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import sharedList from "@/calendesk/mixins/sharedList";
import TableFooterMenu from "@/components/TableFooterMenu.vue";
import websiteActions from "@/calendesk/mixins/websiteActions";
import WebsiteRow from "@/views/dashboard/pages/Tools/Websites/components/WebsiteRow.vue";
import WebsiteFilter from "@/views/dashboard/pages/Tools/Websites/components/WebsiteFilter.vue";

export default {
  components: {
    WebsiteFilter,
    WebsiteRow,
    TableFooterMenu,
    NoDataInfo,
  },
  mixins: [websiteActions, statsActions, sharedList],
  data() {
    return {
      cacheId: "websites-list",
      requestActionName: "fetchWebDrafts",
      statsMode: this.$helpers.statsMode.webDrafts,
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          sortable: true,
          value: "id",
          class: "header-style",
        },
        {
          text: this.$trans("drafts_table_header_title"),
          value: "drafts_table_header_title",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("domain_configuration_form_domain"),
          value: "domain_configuration_form_domain",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("status"),
          value: "status",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "actions",
          sortable: false,
          class: "header-style",
          align: "end",
        },
      ],
    };
  },
  computed: {
    onSelectActions() {
      return [
        {
          title: { single: "disable", many: "disable" },
          action: this.handleDisableDraftsClick,
          icon: "$close",
          color: "error",
          target: "website",
          class: "error--text",
        },
        {
          title: { single: "remove", many: "remove" },
          action: this.handleDeleteWebsitesClick,
          icon: "$trash-default",
          color: "error",
          target: "website",
          class: "error--text",
        },
      ];
    },
  },
  watch: {
    shouldRefreshWebDrafts(status) {
      if (status) {
        this.forceReload();
      }
    },
  },
};
</script>
