<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__40-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("drafts_table_header_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="mb-2">
          {{ item.description }}
        </div>
        <v-chip
          v-if="item.domain && item.domain.is_main"
          color="dark"
          dark
          small
          class="my-2"
          v-text="$trans('wb_main_website')"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("domain_configuration_form_domain") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <template v-if="item.domain">
          <a class="mr-2" :href="getDomain(item)" target="_blank"
            >https://{{ item.domain.domain }}</a
          >
          <v-btn color="blue" icon x-small @click.stop="copyDomain(item)">
            <v-icon>$copy</v-icon>
          </v-btn>
        </template>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("status") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-chip
          v-if="item.published"
          color="success"
          class="green--text"
          dark
          small
          v-text="$trans('published_text')"
        />
        <v-chip
          v-else
          class="red--text"
          color="red-light"
          dark
          small
          v-text="$trans('not_published_text')"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <website-list-button :is-hovered="isHovered" :item="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import WebsiteListButton from "@/views/dashboard/pages/Tools/Websites/components/WebsiteListButton.vue";

export default {
  components: {
    WebsiteListButton,
  },
  mixins: [itemRowActions],
  methods: {
    copyDomain(item) {
      this.$helpers.copyAndNotify(`https://${item.domain.domain}`);
    },
    getDomain(item) {
      return `https://${item.domain.domain}`;
    },
  },
};
</script>
