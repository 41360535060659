import dataTableFooter from "@/calendesk/prototypes/dataTableFooter";
import { debounce } from "debounce";
import { mapActions, mapGetters } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import cacheActions from "@/calendesk/mixins/cacheActions";

export default {
  mixins: [sharedActions, cacheActions],
  data() {
    return {
      cacheId: null, // Override it
      defaultSortBy: "id",
      selected: [],
      items: [],
      selectAllState: false,
      isLoadingTable: false,
      tableOptions: {
        itemsPerPage: 25,
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
      },
      currentResponsePage: 1, // This is the same what tableOptions.page, but the value is assigned after the response.
      tableFilters: null,
      total: null,
      requestActionName: null,
    };
  },
  computed: {
    ...mapGetters({
      getCachedTableOptions: "cache/getTableOptions",
    }),
    footerProps() {
      return dataTableFooter;
    },
    noData() {
      return this.items && this.items.length === 0;
    },
    countAllItems() {
      return this.items.length;
    },
    isIndeterminateForSelectAll() {
      return (
        this.countSelectedOnCurrentPage > 0 &&
        this.countSelectedOnCurrentPage < this.countAllItems
      );
    },
    countSelectedOnCurrentPage() {
      const selectedOnCurrentPage = this.selected.filter(
        (item) => parseInt(item.page) === parseInt(this.currentResponsePage)
      );

      return selectedOnCurrentPage.length;
    },
    selectAllCountedState() {
      return (
        this.countSelectedOnCurrentPage > 0 &&
        this.countSelectedOnCurrentPage === this.countAllItems
      );
    },
  },
  watch: {
    selectAllCountedState(val) {
      this.selectAllState = val;
    },
  },
  created() {
    this.restoreTableOptions();
    // Notice: the initial loading will be done by "filtersRestored" when the filters are restored.
  },
  methods: {
    ...mapActions({
      setCachedTableOptions: "cache/setTableOptions",
    }),
    restoreTableOptions() {
      if (
        this.getCachedTableOptions &&
        this.getCachedTableOptions[this.cacheId]
      ) {
        this.tableOptions = this.$helpers.cloneObject(
          this.getCachedTableOptions[this.cacheId]
        );
      }
    },
    tableOptionsChanged(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        if (newVal.itemsPerPage !== oldVal.itemsPerPage) {
          this.selected = [];
        }
        this.cacheTableOptions();
        this.loadEventsDebounce();
      }
    },
    async forceReload() {
      this.selected = [];
      await this.loadEvents(true);
    },
    cacheTableOptions() {
      if (this.tableOptions) {
        this.setCachedTableOptions({
          cacheId: this.cacheId,
          data: this.$helpers.cloneObject(this.tableOptions),
        });
      }
    },
    filtersChanged(data) {
      this.selected = [];
      this.tableFilters = data;
      this.tableOptions.page = 1;
      this.loadEventsDebounce(true);
    },
    async filtersRestored(data) {
      this.tableFilters = data;
      await this.loadEvents();
      this.$watch("tableOptions", this.tableOptionsChanged, { deep: true });
    },
    selectAllItemsOnChange() {
      if (this.selectAllState) {
        this.items.forEach((item) => {
          const newItem = {
            id: item.id,
            item,
            page: this.tableOptions.page,
          };

          if (!this.isRowSelected(newItem)) {
            this.selected.push(newItem);
          }
        });
      } else {
        this.selected = this.selected.filter(
          (item) => item.page !== this.currentResponsePage
        );
      }
    },
    itemRowEventOnSelectChange(selectable) {
      if (!this.isRowSelected(selectable)) {
        this.selected.push({
          ...selectable,
          page: this.tableOptions.page,
        });
      } else {
        this.selected = this.selected.filter(
          (obj) => obj.id !== selectable.id || obj.type !== selectable.type
        );
        this.selectAllState = false;
      }
    },
    isRowSelected(selectable) {
      return !!this.selected.find((obj) => obj.id === selectable.id);
    },
    assignResponse(response) {
      this.items = response.data;
      this.total = response.total;

      this.currentResponsePage = response.current_page;
      this.selectAllState = this.selectAllCountedState;

      // Check if the current page is valid based on the total number of records
      const totalPages = Math.ceil(this.total / this.tableOptions.itemsPerPage);
      if (
        this.total > 0 &&
        this.items.length === 0 &&
        this.tableOptions.page > totalPages
      ) {
        this.tableOptions.page = 1; // Reset the page to 1
        this.loadEvents(true);
      }

      this.cacheTableOptions();
    },
    getQueryOptions() {
      const data = {
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page ?? 1,
        order_by: this.tableOptions.sortBy[0]
          ? this.tableOptions.sortBy[0]
          : this.defaultSortBy,
        ascending: this.tableOptions.sortDesc[0] ? 0 : 1,
      };

      if (this.tableFilters) {
        Object.assign(data, this.tableFilters);
      }

      return data;
    },
    loadEventsDebounce: debounce(function (ignoreCache) {
      this.loadEvents(ignoreCache);
    }, 400),
    async loadEvents(ignoreCache) {
      this.isLoadingTable = true;

      const dataOptions = this.getQueryOptions();

      if (!ignoreCache) {
        const cachedTableData = await this.getCachedTableData(this.cacheId);

        if (cachedTableData) {
          const cachedOptions = cachedTableData.options;

          if (cachedOptions) {
            if (JSON.stringify(dataOptions) === JSON.stringify(cachedOptions)) {
              if (typeof this.expanded !== "undefined") {
                const cachedTableExpandedData =
                  await this.getCachedTableExpandedData(this.cacheId);

                if (cachedTableExpandedData) {
                  this.expanded = cachedTableExpandedData.data;
                }
              }

              await this.delay(300);
              this.assignResponse(cachedTableData.data);
              this.isLoadingTable = false;
              return true;
            }
          }
        }
      }

      this[this.requestActionName](dataOptions)
        .then((response) => {
          this.assignResponse(response);

          this.setCacheTableData(
            {
              options: dataOptions,
              data: response,
            },
            this.cacheId
          );
        })
        .finally(() => {
          this.isLoadingTable = false;
        });

      return true;
    },
  },
};
