import { mapActions, mapGetters } from "vuex";
import log from "@/lib/calendesk-js-library/tools/log";
import { getAvailableLocationFilters } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cacheId: null, // abstract, implement it
      availableLocationTypeFilters: getAvailableLocationFilters(),
      availableAccountStatusFilters: [
        {
          text: this.$trans("active_accounts"),
          value: "active",
        },
        {
          text: this.$trans("inactive_accounts"),
          value: "inactive",
        },
      ],
      availableSubscriptionStatusFilters: [
        {
          text: this.$trans("active_subscriptions"),
          value: "active",
        },
        {
          text: this.$trans("inactive_subscriptions"),
          value: "inactive",
        },
      ],
      availablePublishedStatusFilters: [
        {
          text: this.$trans("published_text"),
          value: "published",
        },
        {
          text: this.$trans("not_published_text"),
          value: "not_published",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      cachedTableFilters: "cache/getTableFilters",
      getCachedTableOptions: "cache/getTableOptions",
    }),
    filters() {
      log.warn("filters() is an abstract function, implement it.");
      return {};
    },
  },
  created() {
    this.restoreFilters();

    // We don't want to hit too many times when restoring filters.
    this.$watch("filters", this.filtersChanged, { deep: true });
  },
  methods: {
    ...mapActions({
      setCachedTableFilters: "cache/setTableFilters",
    }),
    filtersChanged() {
      this.saveFilters();
      this.$emit("filtersChanged", this.getData());
    },
    getData() {
      log.warn("getData() is an abstract function, implement it.");
      return null;
    },
    clearFilters() {
      for (let filter in this.filters) {
        this[filter] = Array.isArray(this[filter]) ? [] : null;
      }

      this.filtersChanged();
    },
    restoreFilters() {
      if (this.cacheId) {
        if (this.cachedTableFilters && this.cachedTableFilters[this.cacheId]) {
          const cachedFilters = this.$helpers.cloneObject(
            this.cachedTableFilters[this.cacheId]
          );

          for (const filter in this.filters) {
            this[filter] = cachedFilters[filter];
          }
        }
      }

      this.$emit("filtersRestored", this.getData());
    },
    saveFilters() {
      if (this.cacheId) {
        this.setCachedTableFilters({
          cacheId: this.cacheId,
          data: this.$helpers.cloneObject(this.filters),
        });
      }
    },
  },
};
