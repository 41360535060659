import {
  createEventDataFromBookingData,
  createEventDataFromGroupBookingData,
  createEventDataFromUnavailableBookingSlotData,
} from "@/calendesk/tools/calendarHelpers";
import { EventData } from "@/views/dashboard/pages/Calendar/components/EventData";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      fetchBooking: "booking/fetchBooking",
      fetchUnavailableBookingSlot: "booking/fetchUnavailableBookingSlot",
      fetchGroupBooking: "booking/fetchGroupBooking",
    }),
    async parseRawEvent(rawEvent) {
      let parsedEvent = new EventData();

      if (rawEvent.id && rawEvent.type === this.$helpers.eventTypes.booking) {
        const booking = await this.loadBooking(rawEvent.id);

        if (booking) {
          parsedEvent = createEventDataFromBookingData(booking);

          if (rawEvent.duplicate) {
            parsedEvent.id = null;
            parsedEvent.groupId = null;

            if (parsedEvent.multiSlotGroupId) {
              parsedEvent.multiSlotGroupId = null;

              if (
                parsedEvent.user &&
                (!parsedEvent.users || parsedEvent.users.length === 0)
              ) {
                parsedEvent.users = [parsedEvent.user];
              }
            }
            parsedEvent.duplicate = true;
            this.cleanMeetingUrls(parsedEvent);
          }
        } else {
          return null;
        }
      } else if (
        rawEvent.id &&
        rawEvent.type === this.$helpers.eventTypes.unavailableBookingSlot
      ) {
        const unavailableBookingSlot = await this.loadUnavailableBookingSlot(
          rawEvent.id
        );

        if (unavailableBookingSlot) {
          parsedEvent = createEventDataFromUnavailableBookingSlotData(
            unavailableBookingSlot
          );

          if (rawEvent.duplicate) {
            parsedEvent.id = null;
            parsedEvent.groupId = null;
            parsedEvent.duplicate = true;
            this.cleanMeetingUrls(parsedEvent);
          }
        } else {
          return null;
        }
      } else if (
        rawEvent.multiSlotGroupId &&
        rawEvent.type === this.$helpers.eventTypes.groupBooking
      ) {
        const groupBooking = await this.loadGroupBooking(
          rawEvent.multiSlotGroupId
        );

        if (groupBooking) {
          parsedEvent = createEventDataFromGroupBookingData(groupBooking);

          if (rawEvent.duplicate) {
            parsedEvent.id = null;
            parsedEvent.groupId = null;
            parsedEvent.multiSlotGroupId = null;
            parsedEvent.duplicate = true;

            this.cleanMeetingUrls(parsedEvent);
            this.removeDuplicatedUsers(parsedEvent);
          }
        } else {
          return null;
        }
      } else {
        // A new event, so we want to keep all the variables.
        parsedEvent = rawEvent;
      }

      return parsedEvent;
    },
    async loadBooking(bookingId) {
      this.isLoading = true;

      return this.fetchBooking(bookingId)
        .then((booking) => {
          return booking;
        })
        .catch((error) => {
          errorNotification("can_not_find_booking", error, false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async loadUnavailableBookingSlot(unavailableBookingSlotId) {
      this.isLoading = true;

      return this.fetchUnavailableBookingSlot(unavailableBookingSlotId)
        .then((unavailableBookingSlot) => {
          return unavailableBookingSlot;
        })
        .catch((error) => {
          errorNotification(
            "can_not_find_unavailable_booking_slot",
            error,
            false
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async loadGroupBooking(groupBookingId) {
      this.isLoading = true;

      return this.fetchGroupBooking(groupBookingId)
        .then((groupBooking) => {
          return groupBooking;
        })
        .catch((error) => {
          errorNotification("can_not_find_booking", error, false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cleanMeetingUrls(parsedEvent) {
      parsedEvent.googleMeetUrl = null;
      parsedEvent.zoomJoinUrl = null;
      parsedEvent.zoomStartUrl = null;
      parsedEvent.teamsUrl = null;
      parsedEvent.skypeUrl = null;
      parsedEvent.customerWhatsAppUrl = null;
      parsedEvent.employeeWhatsAppUrl = null;
    },
    removeDuplicatedUsers(parsedEvent) {
      if (parsedEvent.users && parsedEvent.users.length > 0) {
        parsedEvent.users = parsedEvent.users.filter(
          (user, index, self) =>
            index === self.findIndex((u) => u.id === user.id)
        );
      }
    },
  },
};
