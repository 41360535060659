<template>
  <v-card v-if="selected.length" flat class="table-footer-menu">
    <v-card-text class="pt-0 pl-3">
      <div class="text-body-1">
        <v-menu top offset-x :close-on-click="true">
          <template #activator="{ on }">
            <v-btn class="mr-3" icon small v-on="on">
              <v-icon> $dots-vertical</v-icon>
            </v-btn>
            {{ $trans("selected_n_positions", { amount: selected.length }) }}
          </template>
          <v-list>
            <div v-for="(el, i) in onSelectActions" :key="i">
              <v-list-item
                v-if="!el.hideOnMultiple"
                @click="
                  el.action && el.action(selectedToSend, el.target, el.params)
                "
              >
                <v-list-item-title
                  :class="el.class ? el.class : ''"
                  class="d-flex align-center"
                >
                  <v-icon class="mr-2" :color="el.color">
                    {{ el.icon }}
                  </v-icon>
                  {{ $trans(el.title.many, { amount: selected.length }) }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
        <v-progress-circular
          v-if="isLoadingInSelectMenu"
          indeterminate
          color="primary"
          size="24"
          class="ml-4"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "TableFooterMenu",
  mixins: [sharedActions],
  props: {
    selected: {
      type: Array,
      required: true,
    },
    onSelectActions: {
      type: Array,
      required: true,
    },
    selectionMap: {
      type: Function,
      required: true,
    },
    isLoadingInSelectMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedToSend() {
      return this.selected.map(this.selectionMap);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-footer-menu {
  position: absolute;
  bottom: 32px;
}
</style>
