export default {
  NONE: 1000,
  USER_REMOVE: 1001,
  USER_LOCK: 1002,
  USER_UNLOCK: 1003,
  SET_NEW_PASSWORD: 1004,
  ADD_TO_GROUP: 1005,
  REMOVE_USER_AVATAR: 1006,
  ADD_OR_UPDATE_GROUP: 1007,
  ADD_CATEGORY: 1008,
  REMOVE_CATEGORY: 1009,
  EDIT_CATEGORY: 1010,
  ADD_OR_UPDATE_RESOURCE: 1011,
  ADD_OR_UPDATE_TAG: 1012,
  REMOVE_RECURRING_EVENT: 1013,
  ADD_STRIPE_PRODUCT: 1014,
  ADD_STRIPE_PRODUCT_PRICE: 1015,
  GDPR_FIELD: 1016,
  AVAILABILITY_SCHEDULE: 1017,
  EDIT_RECURRING_EVENT: 1018,
  REMOVE_EVENTS: 1019,
  CANCEL_USER_SUBSCRIPTION: 1020,
};
