export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    selectOnChange(event) {
      this.$emit("selectOnChange", {
        id: this.item.id,
        item: this.$helpers.cloneObject(this.item),
        event,
      });
    },
    clickRow() {
      this.$emit("clickRow", this.item);
    },
  },
};
